const allowedPositions = [
  // arena
  {
    latitude: 55.817178,
    longitude: 49.124849,
  },
  // city
  {
    latitude: 55.751133,
    longitude: 37.533724,
  },
]

const gedToRad = (deg: number) => {
  return (deg * Math.PI) / 180
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
const calculateCrow = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  var R = 6371 // km
  var dLat = gedToRad(lat2 - lat1)
  var dLon = gedToRad(lon2 - lon1)
  var lat1 = gedToRad(lat1)
  var lat2 = gedToRad(lat2)

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

export const validateGeolocation = async () => {
  return new Promise<boolean>(res => {
    navigator.geolocation.getCurrentPosition(
      geolocation => {
        if (geolocation) {
          res(
            allowedPositions
              .map(({ latitude, longitude }) =>
                calculateCrow(geolocation.coords.latitude, geolocation.coords.longitude, latitude, longitude)
              )
              .some(c => c < 0.5)
          )
        }

        res(false)
      },
      () => {
        res(false)
      },
      {
        timeout: 3000,
      }
    )
  })
}
